<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row dense>
      <v-col cols="12">
        <v-breadcrumbs class="py-2" :items="breadcrumbs">
          <template v-slot:item="{ item }">
            <router-link :to="item.href" class="v-breadcrumbs__item">
              {{ item.text }}
            </router-link>
          </template>
        </v-breadcrumbs>
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-title>
            <span class="text-truncate">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <v-row justify="center">
              <v-col cols="12" sm="auto" class="d-flex child-flex">
                <v-row justify="center" dense>
                  <v-col cols="12" class="d-flex child-flex">
                    <!-- :src="baseURL + 'file_stream/?f=' + props.item['fields.856.link']"
                    :src="baseURL + 'coperta/?pre=mon&rid=' + record._id"
                     -->
                    <v-img
                      v-if="img"
                      :src="img"
                      lazy-src="/image/default/mon/default.png"
                      contain
                      width="200"
                      max-height="300"
                    />
                    <v-img v-else src="/image/default/mon/default.png" contain width="200" max-height="300" />
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      color="red"
                      style="padding:0px!important"
                      class=""
                      tile
                      elevation="0"
                      min-width="28"
                      width="28"
                      height="29"
                      @click="addToFavorite"
                    >
                      <v-icon v-if="isFavorites" size="20">mdi-heart</v-icon>
                      <v-icon v-else size="20">mdi-heart-outline</v-icon>
                    </v-btn>
                    <ShareNetwork
                      v-for="network in networks"
                      :key="network.network"
                      class="white--text pa-1 ml-2"
                      style="text-decoration:none"
                      :network="network.network"
                      :style="{ backgroundColor: network.color }"
                      :url="currentURL"
                      :title="title"
                      :description="description"
                      :quote="quote"
                      :hashtags="keywords"
                    >
                      <v-icon color="white">{{ network.icon }}</v-icon>
                    </ShareNetwork>
                  </v-col>

                  <v-col cols="12" class="d-flex justify-center white--text">
                    <!-- <div class="sharethis-inline-share-buttons"></div> -->
                    <!-- <a class="v-breadcrumbs__item" href="http://www.facebook.com/sharer.php?u=" target="_blank">
                      <v-btn color="blue" elevation="5" icon small>
                        <v-icon size="37">mdi-facebook</v-icon>
                      </v-btn>
                    </a> -->
                  </v-col>
                </v-row>
              </v-col>
              <v-col :md="false">
                <v-simple-table class="mt-3" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in infos" :key="item.uid">
                        <td class="font-weight-bold">
                          {{ $t(item.label) }}
                        </td>
                        <td>
                          <router-link
                            v-if="item.flds"
                            :to="
                              '/cautare-avansata?c=mon&f1=' +
                                item.flds +
                                '&s1==&v1=' +
                                item.value +
                                '&f2=700a&s2=a&v2=&f3=606a&s3=a&v3=&o=&d=&u=ca'
                            "
                            class="v-breadcrumbs__item"
                          >
                            {{ item.value }}
                          </router-link>
                          <template v-else>
                            {{ item.value }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col v-if="terms.length > 0" cols="12" md="4">
                <v-simple-table class="mt-3" dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in terms" :key="item.uid">
                        <td class="font-weight-bold">
                          {{ $t(item.label) }}
                        </td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-text>
            <v-tabs>
              <v-tab><v-icon class="mr-1 mt-1">mdi-playlist-plus</v-icon>{{ $t("Detalii") }}</v-tab>
              <v-tab><v-icon class="mr-1 mt-1">mdi-format-list-numbered</v-icon>{{ $t("Unimarc") }}</v-tab>
              <v-tab><v-icon class="mr-1 mt-1">mdi-view-compact</v-icon>{{ $t("Vizualizare compactă") }}</v-tab>
              <!-- <v-tab v-if="haveResource">
                <v-icon class="mr-1 mt-1">mdi-folder-multiple-image</v-icon>{{ $t("Resurse") }}
              </v-tab> -->
              <v-tab-item class="pt-4">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in details" :key="item.uid">
                        <td :class="item.value ? 'pl-6' : 'font-weight-bold'">
                          {{ $t(item.label) }}
                        </td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
              <v-tab-item class="pt-4">
                <div v-for="uni in unimarc" :key="uni.uid">
                  <span class="teal--text font-weight-bold">
                    {{ uni.fld + " " }}
                  </span>
                  <span class="grey--text font-weight-bold">
                    {{ uni.ind1 + uni.ind2 + " " }}
                  </span>
                  <div v-for="sub in uni.subs" :key="sub.uid" class="d-inline-flex mr-1">
                    <span class="pink--text font-weight-bold">
                      {{ "$" + sub.sub }}
                    </span>
                    <span> {{ sub.val + " " }}</span>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item class="pt-4">
                <div class="body-1">{{ isbd.autor }}</div>
                <div class="ml-4 body-1">{{ isbd.title }}</div>
                <div class="ml-4 body-1">{{ isbd.title_info }}</div>
                <div class="ml-4 body-1">{{ isbd.edition }}</div>
                <div class="ml-4 body-1">{{ isbd.notes }}</div>
                <div class="ml-4 body-1">{{ isbd.volumes }}</div>
                <!-- <div>035a</div>
                <div>700a</div>
                <div>200a+.200c+=200d+:200e+/200f+;200g</div>
                <div>205a+,205b+=205d+/205f+;205g</div>
                <div>210a+;210a+:210c+,210d+(210efgh)+;210f+:210g+,210h</div>
                <div>215a+,215a+:215c+;215d++215e</div>
                <div>(225a+=225d+:225e+/225f+;225f+;225v+,225x)</div>
                <div>010,011,013</div> -->
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="haveResource" cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-title>
            <v-icon class="mr-2 mt-1">mdi-folder-multiple-image</v-icon>
            <span class="text-truncate">{{ $t("Resurse") }}</span>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <!-- <v-row v-if="record.fields" dense>
                  <v-col v-for="(res, ind) in record.fields['855']" :key="'855' + ind" class="py-0" cols="12" md="6">
                    <v-btn color="primary" text @click="getResource(res.link)">{{ res["t"][0].val }}</v-btn>
                  </v-col>
                  <v-col cols="12">
                    <scream-pdf
                      v-if="resource.content_type == 'application/pdf'"
                      :src="baseURL + 'file_stream/?f=' + resource._id"
                      height="600px"
                    />
                    <v-img v-else-if="content_type == 'image'" :src="baseURL + 'file_stream/?f=' + resource._id" />
                    <audio v-else-if="content_type == 'audio'" controls>
                      <source :src="baseURL + 'file_stream/?f=' + resource._id" :type="resource.content_type" />
                    </audio>
                    <video v-else-if="content_type == 'video'" class="v-responsive" controls>
                      <source :src="baseURL + 'file_stream/?f=' + resource._id" :type="resource.content_type" />
                    </video>
                  </v-col>
                </v-row> -->
            <manage-resource
              ref="manageResource"
              class="mb-4 mx-2 mt-2"
              type="catalog"
              :record="record"
              :height="120"
              :width="100"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-title>
            <span class="text-truncate">{{ $t("Inventare") }}</span>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("Barcode/Nr. Inventar") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Număr/Ediție") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Localizare") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Regim resursă") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Disponibil") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Cota") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in inventories" :key="item.barcode">
                    <td>{{ item.barcode }}</td>
                    <td>{{ item.editie }}</td>
                    <td>{{ item.localizare }}</td>
                    <td>{{ item.regim }}</td>
                    <td>{{ item.disponibil }}</td>
                    <td>{{ item.cota }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="mt-0 mb-1">
          <v-card-title>
            <span class="text-truncate">{{ $t("Lăsați un comentariu") }}</span>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <!-- <div class="fb-comments" data-href="https://dev.ebibliophil.ro/" data-width="100%" data-numposts="5"></div> -->
            <div
              class="fb-comments"
              :data-href="currentURL"
              data-numposts="5"
              data-width="100%"
              data-colorscheme="light"
            ></div>
            <!-- <div id="fb-root"></div> -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <script v-if="record._id" type="application/ld+json" v-html="jsonld" />
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
// import ScreamPdf from "../../../components/containers/ScreamPdf"
import ManageResource from "../../../components/containers/ManageResource"
export default {
  name: "DashboardDashboard",
  components: { ManageResource },
  data() {
    return {
      schemaContent: {},
      uid: 1,
      recordId: "",
      record: {},
      resource: {},
      title: "",
      isbd: {},
      description: "",
      quote: "",
      keywords: "",
      unimarc: [],
      uninot: ["916", "917", "006", "856"],
      details: [],
      infos: [],
      terms: [],
      inventories: [],
      dictionary: [],
      book: {
        author: "",
        isbn: "",
        date: "",
        tag: ""
      },
      networks: [
        { network: "facebook", name: "Facebook", icon: "mdi-facebook", color: "#1877f2" },
        { network: "twitter", name: "Twitter", icon: "mdi-twitter", color: "#1da1f2" },
        { network: "whatsapp", name: "Whatsapp", icon: "mdi-whatsapp", color: "#25d366" },
        { network: "telegram", name: "Telegram", icon: "mdi-send", color: "#0088cc" }
        // { network: "email", name: "Email", icon: "mdi-email", color: "#333333" },
        // { network: "sms", name: "SMS", icon: "mdi-message-processing", color: "#333333" }
      ]
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { name: "description", content: this.description },
        { property: "og:title", content: this.title },
        { property: "og:url", content: this.currentURL },
        { property: "og:site_name", content: "eBibliophil" },
        { property: "og:description", content: this.description },
        { property: "og:type", content: "book" },
        { property: "og:image", content: this.img },
        { property: "book:author", content: this.book.author },
        { property: "book:isbn", content: this.book.isbn },
        { property: "book:release_date", content: this.book.date },
        { property: "book:tag", content: this.book.tag }
      ]
    }
  },
  computed: {
    jsonld() {
      const json = {
        "@context": "https://schema.org",
        "@type": "Book",
        name: this.book.name,
        isbn: this.book.isbn,
        datePublished: this.book.date,
        description: this.description,
        inLanguage: this.book.language
      }
      if (this.book.publisher) {
        json.publisher = {
          "@type": "Organization",
          name: this.book.publisher
        }
      }
      if (this.book.authors.length > 0) {
        json.author = []
        this.book.authors.map(itm => {
          json.author.push({
            "@type": itm.type,
            name: itm.name
          })
        })
      }
      return json
    },
    currentURL() {
      if (window.location.href) {
        return window.location.href
      }

      return ""
    },
    img() {
      if (this.resolvePath("fields.856.0.link", this.record)) {
        return this.baseURL + "file_stream/?f=" + this.resolvePath("fields.856.0.link", this.record)
      }
      return ""
    },
    haveResource() {
      this.$log("haveResource ", this.record.fields)
      if (this.record.fields) {
        if (
          this.record.fields["950"] ||
          this.record.fields["953"] ||
          this.record.fields["955"] ||
          this.record.fields["958"] ||
          this.record.fields["959"]
        ) {
          return true
        }
      }
      return false
    },
    content_type() {
      if (this.resource.content_type) {
        if (this.resource.content_type.substr(0, 5) == "image") {
          return "image"
        } else if (this.resource.content_type.substr(0, 5) == "audio") {
          return "audio"
        } else if (this.resource.content_type.substr(0, 5) == "video") {
          return "video"
        }
        return this.resource.content_type
      }
      return ""
    },
    companyId() {
      return this.$store.getters.companyId
    },
    isFavorites() {
      if (this.userFavorites) {
        if (this.userFavorites.indexOf(this.record._id) > -1) {
          return true
        }
      }
      return false
    },
    userFavorites() {
      return this.$store.getters.userFavoritesMon
    },
    userWebId() {
      return this.$store.getters.userWebId
    },
    baseURL() {
      return axios.defaults.baseURL
    },
    appSchema() {
      return this.$store.getters.appSchema
    },
    breadcrumbs() {
      const arr = [
        {
          text: "Acasă",
          disabled: false,
          href: "/"
        },
        {
          text: "Monografii",
          disabled: false,
          href: "/mon"
        }
      ]
      if (this.record.ctg != "" && this.dictionary.find(itm => itm.value == this.record.ctg)) {
        arr.push({
          text: this.dictionary.find(itm => itm.value == this.record.ctg).name,
          disabled: false,
          href: "/mon/cat-" + this.record.ctg
        })
      }
      return arr
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.getRecords()
      }
    },
    $route() {
      //this.$log("route changed ", this.$route)
      if (this.$route.name == "Monografie") {
        this.unimarc = []
        this.infos = []
        this.details = []
        this.terms = []
        this.inventories = []
        this.recordId = this.$route.params.pathMatch
        this.getRecord()
      }
    }
  },
  created() {
    this.recordId = this.$route.params.pathMatch
    this.generateSchemaContent(this.appSchema.filter(itm => itm.collection == "marc_mon")[0].content.fields.schema)
    this.getdictionary("categorii_mon").then(res => {
      this.dictionary = res
    })
    this.getRecord()
  },
  methods: {
    addToFavorite() {
      if (this.userWebId) {
        const fav = [...this.userFavorites]
        if (this.isFavorites) {
          fav.splice(fav.indexOf(this.record._id), 1)
        } else {
          fav.push(this.record._id)
        }
        this.$store.commit("userFavoritesMon", fav)
        this.$store.dispatch("setUserFavoritesMon")
      }
    },
    getdictionary(dic) {
      return new Promise((resolve, reject) => {
        if (this.$store.getters.dictionary("all_" + dic)) {
          resolve(this.$store.getters.dictionary("all_" + dic))
        } else {
          axios
            .get('app_dictionary?where={"value":"' + dic + '"}')
            .then(response => {
              if (response.data._items[0]) {
                this.$store.commit("dictionary", { name: "all_" + dic, values: response.data._items[0].content })
                resolve(response.data._items[0].content)
              }
            })
            .catch(err => {
              this.$log("errrrrrr ", err)
              reject(new Error("Dictionary not found"))
            })
        }
      })
    },
    getResource(id) {
      axios
        .get('ebib_media/?projection={"name":1,"file_name":1,"content_type":1,"size":1}&where={"_id":"' + id + '"}')
        .then(response => {
          this.$log("resource ", response)
          this.resource = response.data._items[0]
        })
    },
    getRecord() {
      axios.get("dataview/record/isbd/mon/" + this.recordId).then(response => {
        this.$log("isbd ", response)
        this.isbd = response.data.isbd
        this.description =
          this.isbd.title +
          (this.isbd.autor ? ", " + this.isbd.autor : "") +
          (this.isbd.title_info ? ", " + this.isbd.title_info : "") +
          (this.isbd.edition ? ", " + this.isbd.edition : "") +
          (this.isbd.notes ? ", " + this.isbd.notes : "") +
          (this.isbd.volumes ? ", " + this.isbd.volumes : "")
      })
      axios.get("ebib_marc_mon/" + this.recordId).then(response => {
        //this.records = []
        this.record = response.data
        this.title = this.record.fields["200"][0].a[0].val
        //document.title = this.title
        //document.querySelector('head meta[name="description"]').content = "descriere"
        //document.querySelector("head title").textContent = this.title
        this.$log("record ", this.record)
        this.addDetails("Autor", "700", "a", true)
        this.addDetails("Editura", "210", "c", true)
        this.addDetails("Loc publicare", "210", "a", true)
        this.addDetails("An", "210", "d", true)
        this.addDetails("Subiect", "606", "a", true)
        this.addDetails("ISBN", "010", "a", false)
        this.addDetails("Note", "300", "a", false)
        let bool = false
        for (const fldi in this.record.fields["606"]) {
          this.terms.push({
            label: bool ? "" : "Termeni de subiect",
            value: this.record.fields["606"][fldi].a[0].val,
            uid: this.uid
          })
          bool = true
          this.uid++
        }
        this.book = {
          name: this.resolvePath("fields.200.0.a.0.val", this.record),
          author: this.resolvePath("fields.700.0.a.0.val", this.record),
          isbn: this.resolvePath("fields.010.0.a.0.val", this.record),
          date: this.resolvePath("fields.210.0.d.0.val", this.record),
          tag: this.resolvePath("fields.606.0.a.0.val", this.record),
          publisher: this.resolvePath("fields.210.0.c.0.val", this.record),
          language: this.resolvePath("fields.101.0.a.0.val", this.record)
        }
        this.book.authors = []

        //this.$log("book ", this.book)
        for (const flds in this.record.fields) {
          if (this.uninot.indexOf(flds) < 0) {
            for (const fld in this.record.fields[flds]) {
              const rec = { fld: flds, subs: [], ind1: "_", ind2: "_", uid: this.uid++ }
              this.details.push({ label: this.schemaContent[flds].label, uid: this.uid++ })
              for (const sub in this.record.fields[flds][fld]) {
                if (sub.length == 1) {
                  for (const subs in this.record.fields[flds][fld][sub]) {
                    this.$log("lbl fld ", flds + sub)
                    if (this.schemaContent[flds + sub]) {
                      this.details.push({
                        label: this.schemaContent[flds + sub].label,
                        value: this.record.fields[flds][fld][sub][subs].val,
                        uid: this.uid++
                      })
                    } else {
                      this.details.push({
                        label: flds + sub,
                        value: this.record.fields[flds][fld][sub][subs].val,
                        uid: this.uid++
                      })
                    }
                    rec.subs.push({ sub: sub, val: this.record.fields[flds][fld][sub][subs].val, uid: this.uid++ })
                    if (
                      flds == "700" ||
                      flds == "701" ||
                      flds == "702" ||
                      flds == "720" ||
                      flds == "721" ||
                      flds == "722"
                    ) {
                      this.book.authors.push({ name: this.record.fields[flds][fld][sub][subs].val, type: "Person" })
                    }
                    if (flds == "710" || flds == "711" || flds == "712") {
                      this.book.authors.push({
                        name: this.record.fields[flds][fld][sub][subs].val,
                        type: "Organization"
                      })
                    }
                    //rec.subs += "$" + sub + this.record.fields[flds][fld][sub][subs].val + " "
                  }
                } else {
                  if (sub == "ind1") {
                    rec.ind1 = this.record.fields[flds][fld][sub]
                  }
                  if (sub == "ind2") {
                    rec.ind2 = this.record.fields[flds][fld][sub]
                  }
                }
              }
              this.unimarc.push(rec)
            }
          }
        }
        this.unimarc.sort((a, b) => {
          return a.fld - b.fld
        })
      })
      axios.get('ebib_marc_inv/?where={"fields.400.link":"' + this.recordId + '"}').then(response => {
        this.$log("res inv ", response)
        response.data._items.map(inv => {
          this.inventories.push({
            barcode:
              this.resolvePath("fields.200.0.a.0.val", inv) + " / " + this.resolvePath("fields.100.0.a.0.val", inv),
            editie: this.resolvePath("fields.200.0.a.0.val", inv),
            localizare: this.resolvePath("fields.501.0.a.0.val", inv),
            regim: this.resolvePath("fields.505.0.a.0.val", inv),
            disponibil: this.resolvePath("fields.700.0.a.0.val", inv),
            cota: this.resolvePath("fields.210.0.a.0.val", inv)
          })
        })
      })
    },
    resolvePath(path, obj, def) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : def
      }, obj || self)
    },
    addDetails(lbl, fld, sub, details) {
      let bool = false
      for (const fldi in this.record.fields[fld]) {
        for (const subi in this.record.fields[fld][fldi][sub]) {
          this.infos.push({
            label: bool ? "" : lbl,
            flds: details ? fld + sub : undefined,
            value: this.record.fields[fld][fldi][sub][subi].val,
            uid: this.uid++
          })
          bool = true
        }
      }
    },
    generateSchemaContent(schema) {
      for (const fld in schema) {
        this.schemaContent[fld] = { label: fld }
        if (schema[fld].meta) {
          if (schema[fld].meta.label) {
            this.schemaContent[fld].label = schema[fld].meta.label
          }
        }
        if (schema[fld].schema != undefined) {
          if (schema[fld].schema.schema) {
            for (const sub in schema[fld].schema.schema) {
              if (sub.length == 1) {
                this.schemaContent[fld + sub] = { label: fld + sub }
                if (schema[fld].schema.schema[sub].schema.schema.val.meta) {
                  if (schema[fld].schema.schema[sub].schema.schema.val.meta.label) {
                    this.schemaContent[fld + sub].label = schema[fld].schema.schema[sub].schema.schema.val.meta.label
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>
<style></style>
